import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./SliderHome.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import MediaCard from "../../ReusedCompnents/Card";

export default function HomeSlider({ data }) {
  const [indexC, setIndexC] = useState(-3);
  return (
    <>
      <Swiper
        slidesPerView={3}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper sliddd"
        style={{ height: "30rem", marginBottom: "2rem" }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
        }}
      >
        <SwiperSlide>
          <img src="/assets/Homeimgg.png" alt="" style={{ height: "100%" }} />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/Homeimgg.png" alt="" />
        </SwiperSlide>{" "}
      </Swiper>
    </>
  );
}
