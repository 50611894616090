import React from "react";
import HeaderPhoto from "../../ReusedCompnents/HeaderPhoto";
import Categories from "../../Components/Medical/Categories";

const MedicalSupplies = ({ open, setOpen, toggleDrawer }) => {
  return (
    <div>
      <HeaderPhoto imgscr="clock.png" />
      <Categories setOpen={setOpen} open={open} toggleDrawer={toggleDrawer} />
    </div>
  );
};

export default MedicalSupplies;
