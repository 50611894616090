import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import "./NavBar.css";
import { InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../Redux/Reducers/Reducers";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

function NavBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const { auth } = useSelector((state) => state.auth);
  console.log(auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation();
  const changeLanguage = () => {
    document.location.reload();
    if (i18n.language === "en-US") i18n.changeLanguage("ar");
    else if (i18n.language === "ar") i18n.changeLanguage("en-US");
  };
  console.log("anaauth,navbn", auth);
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "left",
        border: "1px solid",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
      className="drawertext"
    >
      <List className="listItems" style={{ textAlign: "left" }}>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            type="button"
            onClick={() => {
              const { toggleDrawer } = props;
              toggleDrawer(true);
            }}
          >
            <Link style={{ textDecoration: "none" }}>Open Categories</Link>
          </ListItemButton>
        </ListItem>
        {t("Navbar", { returnObjects: true })?.map((link, index) => (
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} type="button">
              <Link
                key={index}
                to={link.to}
                style={{
                  textAlign: "center",
                  width: "100%",
                  textDecoration: "none",
                }}
              >
                <ListItemText primary={link.text} />
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List className="flex-bet">
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }} onClick={changeLanguage}>
            <ListItemText primary={i18n.language === "en-US" ? "EN" : "AR"} />
          </ListItemButton>
        </ListItem>{" "}
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText
              primary={
                <Link to="/Cart">
                  <ShoppingCartOutlinedIcon />
                </Link>
              }
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton sx={{ textAlign: "center" }}>
            <ListItemText
              primary={
                <Button
                  sx={{ color: "#fff" }}
                  className=""
                  style={{ width: "100%" }}
                >
                  {auth === null ? (
                    <Link to="/Login" style={{ textDecoration: "none" }}>
                      {t("SignIn")}
                    </Link>
                  ) : (
                    <div
                      class="dropdown"
                      style={{ width: "100%", fontSize: "0.8rem" }}
                    >
                      <button class="dropbtn" style={{ padding: "0" }}>
                        <span style={{ color: "black" }}> Welcome! </span>
                        <span className="welcom">
                          {auth !== null && auth.data && auth.data.first_name}
                        </span>
                      </button>
                      <div class="dropdown-content">
                        <Link
                          onClick={() => {
                            dispatch(Logout());
                          }}
                          style={{ padding: "5px 0px" }}
                        >
                          Logout
                        </Link>
                      </div>
                    </div>
                  )}
                </Button>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="navbarCus">
      <CssBaseline />
      <AppBar
        component="nav"
        style={{ position: "relative", background: "#f1eeff" }}
      >
        <Toolbar className="flex-bet">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ ml: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open cat"
            edge=""
            // onClick={() => {
            //   const { toggleDrawer } = props;
            //   toggleDrawer(true);
            //   console.log(props);
            // }}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <img src="/assets/sehaimg.png" alt="" style={{ height: "5rem" }} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img src="/assets/sehalogo.png" alt="" />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }} className="textf ">
            <TextField
              id="outlined-basic"
              variant="outlined"
              className="field"
              style={{ borderRadius: "40px" }}
              dir="rtl"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ background: "white" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button sx={{ color: "red" }} onClick={changeLanguage}>
              {i18n.language === "en-US" ? "EN" : "AR"}
            </Button>
            <Button sx={{ color: "#fff" }}>
              <Link to="/Cart">
                <ShoppingCartOutlinedIcon />
              </Link>
            </Button>
            <Button sx={{ color: "#fff" }} className="sign">
              {auth === null ? (
                <Link to="/Login" style={{ textDecoration: "none" }}>
                  {t("SignIn")}
                </Link>
              ) : (
                <div class="dropdown">
                  <button class="dropbtn">
                    Welcome!{" "}
                    <span className="welcom">
                      {auth !== null && auth.data && auth.data.first_name}
                    </span>
                  </button>
                  <div class="dropdown-content">
                    <Link
                      onClick={() => {
                        dispatch(Logout());
                      }}
                    >
                      Logout
                    </Link>
                  </div>
                </div>
              )}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          anchor={i18n.language === "en-US" ? "left" : "right"}
          // Set anchor to 'right' for Arabic
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default NavBar;
