import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavHeader.css";
import { useTranslation } from "react-i18next";
import { IoIosMenu } from "react-icons/io";
const NavHeader = ({ open, setOpen, toggleDrawer }) => {
  const [activeLink, setActiveLink] = useState(window.location.pathname);
  const path = useLocation();
  console.log(path.pathname);
  const navLinks = [
    { text: "Home", to: "/" },
    { text: "Medical Supplies", to: "/Medical" },
    { text: "Personal Care", to: "/Personal" },
    { text: "Offers", to: "/offers" },
    { text: "Treatment", to: "/treatment" },
  ];
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{ minHeight: "5.9rem", width: "100%" }}
      className="flex-cen navHead"
    >
      {t("Navbar", { returnObjects: true })?.map((link, index) => (
        <Link
          key={index}
          to={link.to}
          className={activeLink === link.to ? "active1" : ""}
          onClick={() => {
            setActiveLink(link.to);
          }}
        >
          {link.text}
        </Link>
      ))}
    </div>
  );
};

export default NavHeader;
