import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Layout from "./Layout/Index";
import MedicalSupplies from "./Pages/Medical/MedicalSupplies";
import ProductDetailsPage from "./Pages/ProductDetail/ProductDetailsPage";
import Personal from "./Pages/Personal/Personal";
import Cart from "./Pages/Cart/Cart";
import Login from "./Pages/Login/Login";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Register from "./Pages/Register/Register";
import { useDispatch, useSelector } from "react-redux";
import Offer from "./Pages/Offers/Offers";
import Treatment from "./Pages/Treatment/Treatment";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";
import { Logout } from "./Redux/Reducers/Reducers";
function App() {
  const { auth } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  console.log("i188888", i18n.language);
  const changeLanguage = () => {
    if (i18n.language === "en") i18n.changeLanguage("ar");
    else if (i18n.language === "ar") i18n.changeLanguage("en");
  };
  const dispatch = useDispatch();
  console.log(t("contact"));
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div className="" dir={i18n.language === "en-US" ? "ltr" : "rtl"}>
      <ToastContainer theme="dark" />
      <Layout setOpen={setOpen} open={open} toggleDrawer={toggleDrawer}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/Medical"
            element={
              <MedicalSupplies
                setOpen={setOpen}
                open={open}
                toggleDrawer={toggleDrawer}
              />
            }
          />
          <Route path="/ProductDetails/:id" element={<ProductDetailsPage />} />
          <Route path="/Personal" element={<Personal />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Offers" element={<Offer />} />
          <Route path="/treatment" element={<Treatment />} />
          {auth === null && (
            <>
              <Route path="/Login" element={<Login />} />
              <Route path="/Register" element={<Register />} />
            </>
          )}
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
