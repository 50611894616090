import React, { useEffect, useState } from "react";
import UseFetch from "../../UseFetch";
import "./Categories.css";
import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MediaCard from "../../ReusedCompnents/Card";
import LoadingCard from "../../ReusedCompnents/Skeleton";
import axios from "axios";
import { baseurl } from "../../BaseUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { IoIosArrowBack } from "react-icons/io";
import "../Home/SliderHome.css";
import { PiTelevisionSimpleBold } from "react-icons/pi";
const Categories = ({ open, setOpen, toggleDrawer }) => {
  const categories = UseFetch("/descendant-categories?parent_id=1");
  const [catdata, setCatdata] = useState(null);
  const [active, setactive] = useState(0);
  const [indexC, setIndexC] = useState(0);
  useEffect(() => {
    if (active !== 0) {
      axios
        .get(baseurl + `/products?category_id=${active}`)
        .then((response) => setCatdata(response.data))
        .catch((error) => console.error("Error fetching categories:", error));
    }
  }, [active]);

  const DrawerList = (
    <Box style={{ padding: "1rem" }}>
      <div
        onClick={() => toggleDrawer(false)}
        style={{ cursor: "pointer", width: "fit-content" }}
      >
        <IoIosArrowBack size={25} />
      </div>
      <List>
        {categories
          ? categories.categories.map((item, index) => (
              <ListItem
                key={item.id}
                disablePadding
                className={`${
                  active === (active === 0 ? index : item.id) ? "active" : ""
                }`}
              >
                <PiTelevisionSimpleBold />
                <ListItemButton>
                  <div
                    style={{
                      width: "100%",
                    }}
                    className="category"
                    onClick={() => {
                      setactive(item.id);
                    }}
                  >
                    <div>
                      {" "}
                      <div>{item.name}</div>
                    </div>
                  </div>
                </ListItemButton>
              </ListItem>
            ))
          : ""}
      </List>
      <Divider />
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Drawer open={open}>{DrawerList}</Drawer>
      <Button
        onClick={() => toggleDrawer(true)}
        style={{ marginBottom: "1rem" }}
      >
        Open Categories
      </Button>
      {active === 0 ? (
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          {categories?.default_products
            ? categories.default_products.map((def) => (
                <Grid key={def.id} item xs={12} md={3} sm={6}>
                  <MediaCard
                    imag={def.base_image.large_image_url}
                    price={def.price}
                    item_id={def.id}
                    indexC={indexC}
                    setIndexC={setIndexC}
                    name={def.name}
                  />
                </Grid>
              ))
            : Array.from({ length: 3 }).map((_, index) => (
                <Grid key={index} item xs={12} md={4} sm={6}>
                  <LoadingCard height={400} width={"100%"} />
                </Grid>
              ))}
        </Grid>
      ) : (
        <Grid container spacing={2} style={{ justifyContent: "center" }}>
          {catdata?.data &&
            catdata.data.map((def) => (
              <Grid key={def.id} item xs={12} md={3} sm={6}>
                <MediaCard
                  imag={def.base_image.large_image_url}
                  price={def.price}
                  item_id={def.id}
                  setIndexC={setIndexC}
                  indexC={indexC}
                  name={def.name}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </Container>
  );
};

export default Categories;
