import React from "react";
import "./Footer.css";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} className="footer flex-cen">
      <Grid item xs={12} sm={6} className="row-cont  " md={4}>
        <div>
          {" "}
          <img src="/assets/sehaimg.png" alt="" />
          <h3
            style={{
              textTransform: "uppercase",
              width: "22.4rem",
              fontSize: "1.7rem",
              color: "#342876",
            }}
          >
            {t("FooterTitle")}
          </h3>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={3} className="row-cont">
        <ul>
          <li>{t("PRIVACY AND POLICY")}</li>
          <li>{t("Payment policy")}</li>
          <li>{t("Shipping policy")}</li>
          <li>{t("Exchange and return policy")}</li>
        </ul>
      </Grid>
      <Grid item xs={12} md={2} sm={6} className="row-cont ">
        <ul>
          <li>{t("profile")}</li>
          <li>{t("Favorite")}</li>
        </ul>
      </Grid>
      <Grid item xs={12} md={3} sm={6} className="row-cont ">
        <ul>
          <li>{t("who are we")}</li>
          <li>{t("Terms and Conditions")}</li>
        </ul>
      </Grid>
      <div style={{ width: "100%" }}>
        <hr style={{ width: "100%" }} />
      </div>
      <div style={{ width: "100%",flexWrap:"wrap" }} className="social flex-bet" >
        <div className="socials">
          {" "}
          <img src="/assets/instagram.png" alt="" />
          <img src="/assets/LinkedIn - Original.png" alt="" />
          <img src="/assets/Group(1).png" alt="" />
          <img src="/assets/envelope (1) 1.png" alt="" />
          <img src="/assets/whatsapp 1.png" alt="" />
          <img src="/assets/phone-call 1.png" alt="" />
        </div>
        <div className="checks">
          <img src="/assets/Frame 45.png" alt=""  />
        </div>
      </div>
    </Grid>
  );
};

export default Footer;
