import React from "react";
import NavBar from "./Nav/NavBar";
import Footer from "./Footer/Footer";
import NavHeader from "./Nav/NavHeader";

const Layout = ({ children, setOpen, open, toggleDrawer }) => {
  console.log("opennnn", open);
  return (
    <div>
      <NavBar setOpen={setOpen} open={open} toggleDrawer={toggleDrawer} />
      <NavHeader setOpen={setOpen} open={open} toggleDrawer={toggleDrawer} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
