import { Grid, Slider } from "@mui/material";
import React from "react";
import "./ProductDetails.css";
import Title from "../../../ReusedCompnents/Title";

import ProductDetailsUp from "./ProductDetailsUp";
import { FaStar } from "react-icons/fa";
const ProductDetailsCom = ({
  data,
  sendor,
  setsendor,
  loadindex,
  mydata,
  setmydata,
  setloadindex,
  id,
}) => {
  console.log(data, "djsjdjhdjhdjh");

  return (
    <>
      <div
        style={{
          border: "2px solid blue",
          padding: "2rem",
          borderRadius: "1.2rem",
          marginBottom: "2rem",
        }}
      >
        <Title title="PRODUCT DETAILS" />
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          <ProductDetailsUp
            data={data}
            setsendor={setsendor}
            sendor={sendor}
            loadindex={loadindex}
            setloadindex={setloadindex}
            mydata={mydata}
            setmydata={setmydata}
            id={id}
          />
        </Grid>
      </div>
      <div
        style={{
          border: "2px solid blue",
          padding: "2rem",
          borderRadius: "1.2rem",
        }}
      >
        <div className="flex-bet" style={{ maxWidth: "11rem" }}>
          <p
            style={{
              color: "#342876",
              fontWeight: "600",
              borderBottom: "2px solid #342876 ",
            }}
          >
            Description
          </p>
          <p> Reviews</p>
        </div>
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          <Grid item xs={12} md={8} sm={6} style={{}}>
            <div
              style={{
                backgroundColor: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                borderRadius: "1.2rem",
                width: "100%",
                padding: "0rem 2rem",
              }}
              className="flex-bet"
            >
              <div
                style={{
                  height: "100%",
                  width: "fit-content",
                  padding: "1.4rem 0rem",
                }}
                className=" flex-col"
              >
                <img
                  src="/assets/reviewphoto.png"
                  alt=""
                  style={{ height: "4.5rem" }}
                />
                <p>Name</p>
                <p>12 May</p>
              </div>
              <div
                style={{
                  width: "100%",
                  minHeight: "4.3rem",
                  padding: "0rem 4rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                </div>
                <div>Highly detailed quality product</div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={6} className="flex-bet">
            <div
              style={{
                minHeight: "23rem",
                background: "white",
                width: "100%",
                borderRadius: "1.2rem",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)",
                padding: "1rem",
              }}
            >
              <div className="flex-col" style={{ fontSize: "1.4rem" }}>
                {" "}
                <p style={{ textAlign: "center" }}>Average Rating</p>
                <p style={{ textAlign: "center" }}>4/5</p>
                <div>
                  {" "}
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                  <FaStar color="#F4B718" />
                </div>
              </div>
              <div className="flex-col ">
                <div
                  className=" flex-bet"
                  style={{
                    width: "100%",
                  }}
                >
                  <div>5 Star</div>
                  <div>
                    {" "}
                    <Slider
                      defaultValue={80}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      style={{ width: "10rem", color: "#342876" }}
                      disabled // This will disable the slider
                    />
                  </div>
                  <div>52k</div>
                </div>
                <div style={{ width: "100%" }} className="flex-bet">
                  <div>4 Star</div>
                  <div>
                    {" "}
                    <Slider
                      defaultValue={70}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      style={{ width: "10rem", color: "#342876" }}
                      disabled // This will disable the slider
                    />
                  </div>
                  <div>42k</div>
                </div>{" "}
                <div className="flex-bet" style={{ width: "100%" }}>
                  <div>3 Star</div>
                  <div>
                    {" "}
                    <Slider
                      defaultValue={40}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      style={{ width: "10rem", color: "#342876" }}
                      disabled // This will disable the slider
                    />
                  </div>
                  <div>32k</div>
                </div>{" "}
                <div className="flex-bet" style={{ width: "100%" }}>
                  <div>2 Star</div>
                  <div>
                    {" "}
                    <Slider
                      defaultValue={20}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      style={{ width: "10rem", color: "#342876" }}
                    />
                  </div>
                  <div>32k</div>
                </div>{" "}
                <div className="flex-bet" style={{ width: "100%" }}>
                  <div>1 Star</div>
                  <div>
                    {" "}
                    <Slider
                      defaultValue={10}
                      aria-label="Default"
                      valueLabelDisplay="auto"
                      style={{ width: "10rem", color: "#342876" }}
                      disabled // This will disable the slider
                    />
                  </div>
                  <div>22k</div>
                </div>{" "}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProductDetailsCom;
