import React from "react";
import "./CardProductCheck.css";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import { baseurl } from "../../BaseUrl";
import { useSelector } from "react-redux";

const CardProductCheck = ({ price, tax, grand }) => {
  const { t } = useTranslation();
  const { cart_id, auth } = useSelector((state) => state.auth);

  return (
    <div className="cardcheck">
      <div className="detailsinfo">
        <p style={{ color: "#342876", fontWeight: "600" }}>{t("Title")}</p>
        <div className="flex-bet infor">
          <div>{t("Sub-Total")}</div>
          <div>{price}</div>
        </div>
        <div className="flex-bet infor">
          <div>{t("VAT")}</div>
          <div>{tax}</div>
        </div>
        <div className="flex-bet infor">
          <div>{t("Total")}</div>
          <div style={{ color: "#342876" }}>{grand}</div>
        </div>
      </div>
      <div>
        <div>
          <Button
            variant="primary"
            style={{ width: "100%" }}
            className="checkout"
            onClick={() => {
              if (auth !== null) {
                axios
                  .post(
                    baseurl + "/checkout/save-order",
                    {
                      cart_id: cart_id,
                      checkout_method: {
                        method: "paypal_standard",
                      },
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${auth.token}`,
                      },
                    }
                  )
                  .then((response) => {
                    const { redirect_url } = response.data;
                    window.open(redirect_url, "_blank");
                  })
                  .catch(() => {
                    toast.error("catch");
                  });
              } else if (auth === null) {
                toast.error("Please Login First");
              }
            }}
          >
            {t("Checkout")}
          </Button>
        </div>
        <div>
          <Button
            variant="primary"
            style={{ width: "100%" }}
            className="shopping"
          >
            {t("Continue Shopping")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardProductCheck;
