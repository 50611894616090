import React from "react";
import HeaderPhoto from "../ReusedCompnents/HeaderPhoto";
import Title from "../ReusedCompnents/Title";
import SliderHome from "../Components/Home/SliderHome";
import UseFetch from "../UseFetch";
import Slider2Home from "../Components/Home/Slider2Home";
import Loading from "../ReusedCompnents/Loading";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import HomeSlider from "../Components/Home/HomeSlider";

const Home = () => {
  const data = UseFetch("/home?count=4");
  const { i18n } = useTranslation();
  return (
    <div style={{ width: "100%" }}>
      <HomeSlider />
      <div style={{ padding: "0rem 5rem" }}>
        <Title
          title={i18n.language === "ar" ? "الاكثر شهرة" : "MOST POPULAR"}
        />
      </div>
      <Container maxWidth="lg" style={{ maxWidth: "" }}>
        <div>
          {data ? (
            <SliderHome data={data} />
          ) : (
            <div style={{ width: "100%" }} className="flex-cen">
              <Loading />
            </div>
          )}
        </div>
      </Container>
      <div style={{ width: "100%" }}>
        <img
          src="/assets/bannersah.png"
          style={{ width: "100%", objectFit: "contain" }}
          alt=""
        />
      </div>
      <div style={{ padding: "0rem 5rem" }}>
        <Title
          title={i18n.language === "ar" ? "احدث العروض" : "LATEST OFFERS"}
        />
      </div>
      <Container maxWidth="lg" style={{ maxWidth: "" }}>
        <div
          style={{
            width: "100%",
          }}
        >
          {data ? (
            <Slider2Home data={data} />
          ) : (
            <div style={{ width: "100%" }} className="flex-cen">
              <Loading />
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Home;
