import { Button, Container, Grid, TextField } from "@mui/material";
import DataTable from "react-data-table-component";
import "./Cart.css";
import Title from "../../ReusedCompnents/Title";
import CardProductCheck from "../../Components/CartProductCheck.js/CardProductCheck";
import UseFetch from "../../UseFetch";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseurl } from "../../BaseUrl";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CiLocationOn } from "react-icons/ci";
export default function Cart() {
  const { cart_id } = useSelector((state) => state.auth);
  const [mydata, setmydata] = React.useState({
    quantity: 0,
    product_id: "0",
  });
  const [sendor, setsendor] = React.useState(false);

  const [data3, setData] = useState(null);
  const sendPostRequest = async () => {
    try {
      const response = await axios.post(
        `https://sehahome.com/api/checkout/cart/add/${mydata.product_id}`,
        { ...mydata, cart_id: cart_id }
      );
      response.data.items && setData(response.data);
      console.log(response.data);
      getRequest();
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };
  const getRequest = async () => {
    try {
      const response = await axios.get(
        baseurl + `/checkout/cart?cart_id=${cart_id}`
      );
      response.data && setData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error making POST request:", error);
    }
  };
  React.useEffect(() => {
    // Trigger the post request when mydata changes
    if (mydata.product_id != 0) sendPostRequest();
  }, [sendor]);
  useEffect(() => {
    getRequest();
  }, []);
  const { t } = useTranslation();
  const columns = [
    {
      name: t("Product Name"),
      selector: (row) => (
        <div className="product-info flex-cen">
          <div
            className="dele-icon"
            onClick={() => {
              axios
                .get(
                  baseurl +
                    `/checkout/cart/remove-item/${row.id}?cart_id=${cart_id}`
                )
                .then((res) => {
                  toast.success("Item Removed ", {
                    position: "top-left",
                  });
                  if (res.data !== null) setData(res.data);
                  else if (res.data == null) setData(null);
                })
                .catch(() => {
                  toast.error("something went wrong ", {
                    position: "top-left",
                  });
                });
            }}
          >
            <CloseIcon />
          </div>
          <img
            src={row?.product?.base_image.medium_image_url}
            alt={row.name}
            className="product-image"
            style={{ maxHeight: "6rem" }}
          />
          <span
            style={{
              maxWidth: "9rem",
              textOverflow: "ellipsis",
              wordWrap: "break-word",
            }}
          >
            {row.name}
          </span>
        </div>
      ),
      width: "400px",
    },
    {
      name: t("Quantity"),
      selector: (row) => (
        <div
          className="flex-bet calc"
          style={{
            width: "100%",
            height: "2.5rem",
          }}
        >
          <Button
            className="subCart"
            onClick={() => {
              setmydata((prevData) => ({
                ...prevData,
                quantity: -1,
                product_id: row.additional.product_id,
              }));
              setsendor(!sendor);
            }}
          >
            <hr />
          </Button>
          <Button className="quanCart">{row.quantity}</Button>
          <Button
            className="addCart"
            onClick={(e) => {
              setmydata((prevData) => ({
                ...prevData,
                quantity: 1,
                product_id: row.additional.product_id,
              }));
              setsendor(!sendor);
            }}
          >
            <span>+</span>
          </Button>
        </div>
      ),
      width: "300px",
    },
    {
      name: t("Product Price"),
      selector: (row) => <div style={{ color: "#342876" }}>{row.price}</div>,
    },

    {
      name: t("Total"),
      selector: (row) => <div style={{ color: "#342876" }}>{row.total}</div>,
    },
  ];

  const data = [
    {
      id: 1,
      image: "/assets/bottle.png",
      title: "NUNU STERILIZER 100ML",
      quant: "",
      price: "10.SAR",
      tot: "10.SAR",
    },
  ];
  return (
    <Container maxWidth="lg">
      <Title title={t("Shopping")} />
      <DataTable
        columns={columns}
        data={data3 !== null ? data3?.data?.items : []}
        className="mytable"
        style={{ marginBottom: "4rem" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <TextField
            label={
              <div className="flex-cen">
                <span style={{ marginLeft: "1rem" }}>Discount Code</span>
              </div>
            }
            id="margin-dense"
            margin="dense"
            style={{
              margin: "0",
              marginBottom: "2rem",
              borderRadius: "20px",
            }}
            className="fieeeld"
            fullWidth
            InputProps={{
              endAdornment: (
                <Button
                  style={{
                    backgroundColor: "#342876",
                    borderRadius: "20px",
                    color: "white",
                    textTransform: "capitalize",
                    padding: "0.5rem 2rem",
                  }}
                >
                  Apply
                </Button>
              ),
            }}
          />
          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12} style={{ fontSize: "1.2rem" }}>
              <p style={{ color: "#342876", fontWeight: "600" }}> Address</p>
              <p style={{ color: "#5A5A5A" }}> Riyadh,93rd Street</p>
              <p
                style={{
                  color: "#342876",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <CiLocationOn /> <span>Add New Address</span>
              </p>
            </Grid>
            <Grid item xs={12} md={7} style={{}}>
              <div
                style={{
                  width: "100%",
                  padding: "2rem",
                  background: "white",
                  borderRadius: "2rem",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                }}
              >
                <p
                  style={{
                    color: "#342876",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                  }}
                >
                  {" "}
                  Add New Address
                </p>
                <TextField
                  label={
                    <div className="flex-cen">
                      <span style={{ marginLeft: "1rem" }}>Country</span>
                    </div>
                  }
                  id="margin-dense"
                  margin="dense"
                  fullWidth
                />{" "}
                <TextField
                  id="margin-dense"
                  margin="dense"
                  fullWidth
                  label={
                    <div className="flex-cen">
                      <span style={{ marginLeft: "1rem" }}>city</span>
                    </div>
                  }
                />{" "}
                <TextField
                  id="margin-dense"
                  margin="dense"
                  fullWidth
                  label={
                    <div className="flex-cen">
                      <span style={{ marginLeft: "1rem" }}>Street Address</span>
                    </div>
                  }
                />
                <Button
                  fullWidth
                  type="submit"
                  style={{
                    backgroundColor: "#342876",
                    color: "white",
                    fontSize: "1.2rem",
                    fontWeight: "500",
                    marginTop: "1rem",
                    textTransform: "capitalize",
                  }}
                >
                  Add New Address
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          {data3?.data !== null ? (
            <CardProductCheck
              price={data3?.data.sub_total}
              tax={data3?.data.tax_total}
              grand={data3?.data.grand_total}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
